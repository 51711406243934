import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from '../components/Layout'
import { OldBlogPostQuery } from '../../types/graphql-types'
import { OldBlogPageContext } from '../../.gatsby/gatsby-node'
import BlogPost from '../components/BlogPost'

const nextData = ({ next, nextNew }: OldBlogPostQuery) => {
  if (next) return { id: next.id, title: next.title?.rendered }
  else if (nextNew) return { id: nextNew.blogsId, title: nextNew.title }
  else return undefined
}

const OldBlogPostTemplate: React.FC<PageProps<
  OldBlogPostQuery,
  OldBlogPageContext
>> = ({ data, location }) => {
  const { post, previous, next, nextNew } = data

  return (
    <Layout path="Blog" title={post?.title?.rendered}>
      <BlogPost
        title={post?.title?.rendered}
        date={post?.date}
        body={post?.content?.rendered}
        previous={
          previous
            ? { id: previous.id, title: previous.title?.rendered }
            : undefined
        }
        next={nextData({ next, nextNew })}
        url={location.href}
      />
    </Layout>
  )
}

export default OldBlogPostTemplate

export const pageQuery = graphql`
  query OldBlogPost(
    $slug: String!
    $previous: String
    $next: String
    $nextNew: String
  ) {
    post: wordpressJson(id: { eq: $slug }) {
      content {
        rendered
      }
      excerpt {
        rendered
      }
      title {
        rendered
      }
      date
    }
    previous: wordpressJson(id: { eq: $previous }) {
      id
      title {
        rendered
      }
    }
    next: wordpressJson(id: { eq: $next }) {
      id
      title {
        rendered
      }
    }
    nextNew: microcmsBlogs(blogsId: { eq: $nextNew }) {
      blogsId
      title
    }
  }
`
